<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Spinner v-if="isLoading"></Spinner>
        <template v-if="!isLoading && customerData">
          <Toolbar class="p-mb-4">
<!--            <template v-slot:left>-->
            <template #start>
              <div style="font-size: 1.4em; color: inherit">{{ $t('Customer') }}:
                <span v-if="customerData">
                  <span v-if="customerData.type === constants.userTypes.legal_entity && customerData.company_name">{{ customerData.company_name }}</span>
                  <span v-else>{{ customerData.first_name }} {{ customerData.last_name }}</span>
                </span>
              </div>
            </template>
<!--            <template v-slot:right>-->
            <template #end>
              <Button
                  v-if="customerData"
                  :disabled="!checkUserCanEditCustomer(customerData.role) || disableEditButton || !allDataLoaded"
                  label="Edit customer"
                  icon="ti-pencil"
                  @click="editItem"
                  class="p-button-success p-button-raised"/>
            </template>
          </Toolbar>

          <TabMenu :model="tabMenuItems" class="p-p-1"/>

          <router-view @update-items="updateItems" :user="customerData" @change-customer-status="changeCustomerStatus"/>

          <CustomerModal :visible="itemModal"
                         :item="item"
                         :taxes="taxes"
                         @update-items="updateItems"
                         @close="closeItemModal">
          </CustomerModal>
        </template>
        <div v-else-if="!customerLoadingStarted && !customerData" class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import settings from '@/settings.js'
import constants from '@/constants'
import CustomerModal from '@/pages/users/components/CustomerModal'
import permissionsMixins from '@/mixins/permissionsMixins'
import httpMixins from '@/mixins/httpMixins'

export default {
  mixins: [ permissionsMixins, httpMixins ],
  components: { CustomerModal },
	data() {
		return {
      // selectedCustomer: { id: null },
      disableEditButton: false,
      customerLoadingStarted: false,
      itemModal: false,
      item: {},
      taxes: null,
      userId: null,
      isLoading: true,
      customerData: null,
      settings,
      tabMenuItems: [],
      constants
    }
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('customers') === -1 || route.indexOf(this.$route.params.id) === -1) {
        return false
      }

      if (!this.customerData) {
        if (this.$route.params.id) {
          this.getCustomerData()
        }
      } else {
        if (this.$route.params.id && +this.$route.params.id !== this.customerData.id) {
          this.getCustomerData()
        }
      }
    },
    '$i18n.locale'() {
      if (this.customerData) {
        this.getTabMenu()
      } else {
        if (!this.customerLoadingStarted && this.$route.params.id) {
          this.getCustomerData()
        }
      }
    },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.itemModal) {
          this.closeItemModal()
        }
        if (this.confirmItemDeleteModal) {
          this.closeConfirmItemDeleteModal()
        }
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    if (!this.$route.params.id) return false
    this.getCustomerData()
	},
  methods: {
    changeCustomerStatus(newStatus) {
      if (this.customerData) {
        if (this.customerData.customerStatus) {
          this.customerData.customerStatus.status_id = newStatus
        } else {
          this.customerData.customerStatus = {}
          this.customerData.customerStatus.status_id = newStatus
        }
      }
    },
    editItem() {
      if (this.customerData) {
        this.item = this.customerData
        this.itemModal = true
        this.$store.commit('toggleFirstLayer', true)
      }
    },
    updateItems() {
      this.disableEditButton = true
      if (this.$route.params.id) {
        this.getCustomerData(false)
      }
    },
    closeItemModal() {
      this.itemModal = false
      if (this.$store.state.firstLayerIsOpened === true ) {
        this.$store.commit('toggleFirstLayer', false)
      }
      this.item = {}
      this.submitted = false
    },
    getTabMenu() {
      this.tabMenuItems = [
        {label: this.$t('General information'), icon: 'ti-user', to: `/customers/${this.$route.params.id}/overview`},
        {label: this.$t('Orders'), icon: 'ti-hummer', to: `/customers/${this.$route.params.id}/orders`},
        {label: this.$t('Offers'), icon: 'ti-clipboard', to: `/customers/${this.$route.params.id}/offers`},
        {label: this.$t('Cars'), icon: 'ti-car', to: `/customers/${this.$route.params.id}/cars`},
      ]


      // this.tabMenuItems[0].label = this.$t('General information')
      if (this.customerData && this.customerData.ordersCount) {
        this.tabMenuItems[1].label += ` (${this.customerData.ordersCount || 0})`
      }
      if (this.customerData && this.customerData.offersCount) {
        this.tabMenuItems[2].label += ` (${this.customerData.offersCount || 0})`
      }
      if (this.customerData && this.customerData.carsCount) {
        this.tabMenuItems[3].label += ` (${this.customerData.carsCount || 0})`
      }
    },
    async getCustomerData(showSpinner = true) {
      this.isLoading = showSpinner
      this.customerLoadingStarted = true
      try {
        const { data, status } = await httpClient.post('user/get-user-by-id?expand=creator,tax,ordersCount,offersCount,carsCount,address,customerStatus', { id: this.$route.params.id })
        if (status === 200 && data) {
          this.customerData = data
          this.getTabMenu()
          this.getTaxes()
        } else {
          this.customerData = null
        }
      } catch(err) {
        this.showError(err)
      }
      this.disableEditButton = false
      this.customerLoadingStarted = false
      this.isLoading = false
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.taxes)
    },
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>